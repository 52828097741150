@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&family=Varela&display=swap');
@import '~antd/dist/antd.less';

* {
  box-sizing: border-box;
}
.App-body {
  height: 100vh;
  width: 100vw;
}

@primary-color: #4963fe;@secondary-color: #45D470;